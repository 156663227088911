import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataSortModule } from './data-sort/data-sort.module';
import { FormAutocompleteSelectModule } from './form-autocomplete-select/form-autocomplete-select.module';
import { FormButtonSelectorModule } from './form-button-selector/form-button-selector.module';
import { FormChipsAutocompleteModule } from './form-chips-autocomplete/form-chips-autocomplete.module';
import { FormChipsModule } from './form-chips/form-chips.module';
import { FormMultiSelectModule } from './form-multi-select/form-multi-select.module';
import { FormSingleSelectModule } from './form-single-select/form-single-select.module';
import { PaginationModule } from './pagination/pagination.module';
import { ChartMultiColumnModule } from './chart-multi-column/chart-multi-column.module';
import { ChartMultiLineModule } from './chart-multi-line/chart-multi-line.module';
import { TilesModule } from './tiles/tiles.module';
import { TabModule } from './tab/tab.module';
import { FormPeriodDatePickerModule } from './form-period-date-picker/form-period-date-picker.module';
import { FormCardSelectorModule } from './form-card-selector/form-card-selector.module';
import { FormDateRangeSelectorModule } from './form-date-range-selector/form-date-range-selector.module';
import { CardStatisticMessageModule } from './card-statistic-message/card-statistic-message.module';
import { FeatureFlagNewMenuGuard } from './utils/feature-flag-new-menu.guard';
import { CardCircleChartModule } from './card-circle-chart/card-circle-chart.module';
import { DataKeyValueModule } from './data-key-value/data-key-value.module';
import { LoadingMaskModule } from './loading-mask/loading-mask.module';
import { MessageBubbleModule } from './message-bubble/message-bubble.module';
import { ChartPieVariableModule } from './chart-pie-variable/chart-pie-variable.module';
import { RaisedButtonModule } from './raised-button/raised-button.module';
import { AutocompleteSelectModule } from './autocomplete-select/autocomplete-select.module';
import { BadgeModule } from './badge/badge.module';
import { SpanModule } from './span/span.module';
import { TypeaheadSelectModule } from './typeahead-select/typeahead-select.module';
import { CampaignIdSelectorModule } from './containers/campaign-id-selector/campaign-id-selector.module';
import { AutocompleteMultiSelectModule } from './autocomplete-multiselect/autocomplete-multiselect.module';
import { CardInitiationTypeModule } from './card-initiation-type/card-initiation-type.module';
import { OverlayTextFilterModule } from './overlay-text-filter/overlay-text-filter.module';
import { OverlaySelectFilterModule } from './overlay-select-filter/overlay-select-filter.module';
import { LastTimeRealTimeInfoModule } from './containers/last-time-real-time-info/last-time-real-time-info.module';
import { ChartSankeyDiagramComponent } from './chart-sankey-diagram/chart-sankey-diagram.component';
import { ChartSankeyDiagramModule } from './chart-sankey-diagram/chart-sankey-diagram.module';
import { CardValueMessageModule } from './card-value-message/card-value-message.module';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { TileKeyValueIndicatorModule } from './tile-key-value-indicator/tile-key-value-indicator.module';
import { CardKeyValueInvertModule } from './card-key-value-invert/card-key-value-invert.module';
import { CardCircleChartStatsModule } from './card-circle-chart-stats/card-circle-chart-stats.module';
import { ChartCircMultileModule } from './chart-circle-multi/chart-circle-multi.module';
import { CardProgressBarModule } from './card-progress-bar/card-progress-bar.module';
import { ChartCircMultiStackedModule } from './chart-circle-multi-stacked/chart-circle-multi-stacked.module';
import { KeyValueLegendModule } from './key-value-legend/key-value-legend.module';
import { FormTimepickerModule } from './form-timepicker/form-timepicker.module';
import { CustomerReasonSelectorModule } from './containers/customer-reason-selector/customer-reason-selector.module';
import { CampaignIdsSelectorModule } from './containers/campaign-ids-selector/campaign-ids-selector.module';
import { ChartBarModule } from './chart-bar/chart-bar.module';
import { ChannelProductCombinationValidator } from './utils/custom-validators';
@NgModule({
  imports: [
    CommonModule,
    FormButtonSelectorModule,
    FormAutocompleteSelectModule,
    FormMultiSelectModule,
    FormSingleSelectModule,
    DataSortModule,
    PaginationModule,
    FormChipsAutocompleteModule,
    FormChipsModule,
    ChartMultiColumnModule,
    ChartMultiLineModule,
    TilesModule,
    TabModule,
    FormPeriodDatePickerModule,
    FormCardSelectorModule,
    FormDateRangeSelectorModule,
    CardStatisticMessageModule,
    CardCircleChartModule,
    DataKeyValueModule,
    LoadingMaskModule,
    MessageBubbleModule,
    ChartPieVariableModule,
    RaisedButtonModule,
    AutocompleteSelectModule,
    BadgeModule,
    SpanModule,
    TypeaheadSelectModule,
    CampaignIdSelectorModule,
    CampaignIdsSelectorModule,
    AutocompleteMultiSelectModule,
    CardInitiationTypeModule,
    OverlayTextFilterModule,
    OverlaySelectFilterModule,
    LastTimeRealTimeInfoModule,
    ChartSankeyDiagramModule,
    CardValueMessageModule,
    ProgressBarModule,
    TileKeyValueIndicatorModule,
    CardKeyValueInvertModule,
    CardCircleChartStatsModule,
    ChartCircMultileModule,
    CardProgressBarModule,
    ChartCircMultiStackedModule,
    KeyValueLegendModule,
    FormTimepickerModule,
    CustomerReasonSelectorModule,
    ChartBarModule,
  ],
  exports: [
    FormButtonSelectorModule,
    FormAutocompleteSelectModule,
    FormMultiSelectModule,
    FormSingleSelectModule,
    DataSortModule,
    PaginationModule,
    FormChipsAutocompleteModule,
    FormChipsModule,
    ChartMultiColumnModule,
    ChartMultiLineModule,
    TilesModule,
    TabModule,
    FormPeriodDatePickerModule,
    FormCardSelectorModule,
    FormDateRangeSelectorModule,
    CardStatisticMessageModule,
    CardCircleChartModule,
    DataKeyValueModule,
    LoadingMaskModule,
    MessageBubbleModule,
    ChartPieVariableModule,
    RaisedButtonModule,
    AutocompleteSelectModule,
    BadgeModule,
    SpanModule,
    TypeaheadSelectModule,
    CampaignIdSelectorModule,
    CampaignIdsSelectorModule,
    AutocompleteMultiSelectModule,
    CardInitiationTypeModule,
    OverlayTextFilterModule,
    OverlaySelectFilterModule,
    LastTimeRealTimeInfoModule,
    ChartSankeyDiagramModule,
    CardValueMessageModule,
    ProgressBarModule,
    TileKeyValueIndicatorModule,
    CardKeyValueInvertModule,
    CardCircleChartStatsModule,
    ChartCircMultileModule,
    CardProgressBarModule,
    ChartCircMultiStackedModule,
    KeyValueLegendModule,
    FormTimepickerModule,
    CustomerReasonSelectorModule,
    ChartBarModule,
    ChannelProductCombinationValidator
  ],
  declarations: [
    ChannelProductCombinationValidator
  ],
  providers: [
    FeatureFlagNewMenuGuard
  ]
})
export class SharedModule { }
