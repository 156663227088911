import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { UniNewNavEnabledService } from '../../shared';
import { UniNavService } from '../../shared/uni-nav.service';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { filter, take } from 'rxjs/operators';
import { Workspace } from '../../../uni-login/shared/login.model';
import { LoginFacade } from '../../../uni-login/shared/login.facade';
import { AutoUnsubscribe } from '../../../../utils';
import { Subscription } from 'rxjs';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';

@Component({
  selector: 'uni-nav-new',
  templateUrl: './uni-nav.component.html',
  styleUrls: ['./uni-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavComponent extends AutoUnsubscribe implements OnInit {
  navModel$ = this.uniNavService.navModel$;
  isNavCollapsed$ = this.uniNavService.isNavCollapsed$;
  newMenuEnabled$ = this.uniNewNavEnabledService.isNewMenuEnabled$;
  isMenuOpen = false;
  isAdmin = this.uniAuthFacade.isAdminLevel();
  accountName = this.uniAuthFacade.user?.account?.name;
  parentAccount = this.uniAuthFacade.user?.account?.parentAccount;
  workspaces$ = this.loginFacade.workspaces$.pipe(
    filter((items) => items && items.length > 1 && !this.isAdmin)
  );

  constructor(
    private readonly uniNavService: UniNavService,
    private readonly uniNewNavEnabledService: UniNewNavEnabledService,
    private loginFacade: LoginFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    protected uniAuthFacade: UniAuthFacade,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.isAdmin) {
      this.subs.add(
        this.setWorkspaces()
      );
    }
  }

  toggleHamburgerMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  isSelected(workspace: Workspace) {
    return workspace.id === this.uniAuthFacade.user?.account?.id;
  }

  onImpersonate(workspace: Workspace) {
    const id = this.uniAuthFacade.user.id;
    this.loginFacade.putSelectedAccount(id, workspace.id);
  }

  getWorkspaceName(workspace: Workspace, workspaces: Workspace[]) {
    const parentAccount = workspaces.find((item) => !item.parentName);

    if (!!workspace.parentName && workspace.parentName !== parentAccount?.name) {
      return `${workspace.parentName} - ${workspace.name}`;
    }

    return workspace.name;
  }

  setWorkspaces(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter((featureFlags) => !!featureFlags),
        take(1)
      )
      .subscribe(
        (flags) =>
          this.uniFeatureFlagsService.getPermission(
            FeatureFlagKeys.organization_2024_q_3,
            flags
          ) && this.loginFacade.setWorkspaces(this.uniAuthFacade.user?.id)
      );
  }
}
