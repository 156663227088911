import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { Channels } from "./channels.store";



export enum AlarisSmsStatus {
  ROUTE_FAILED = "ROUTE FAILED",
  NO_ACTIVE_SMSRT = "NO ACTIVE SMSRT",
  VND_CHN_NOT_BND = "VND CHN NOT BND",
  BUFFERED_VLB = "BUFFERED_VLB",
  SAME_CHN_SKIPPED_ON_REROUTE = "SAME CHN SKIPPED ON REROUTE",
  VND_CHNL_SBM_QUEUE_OVL = "VND CHNL SBM QUEUE OVL",
  SENT = "SENT",
  NO_ROUTES = "NO ROUTES",
  BUFFERED_BY_RULE = "BUFFERED_BY_RULE",
  SUBMIT_RESP_TIMEOUT = "SUBMIT_RESP TIMEOUT"

}


type State = {
    loading: boolean
    loaded: boolean
    entities: {
        label: string,
        value: string
    }[]
    
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [
        {
          label: 'ci.common.alaris.route_failed',
          value: AlarisSmsStatus.ROUTE_FAILED,
        },{
          label: 'ci.common.alaris.no_active_smsrt',
          value: AlarisSmsStatus.NO_ACTIVE_SMSRT
        },{
          label: 'ci.common.alaris.vnd_chn_not_bnd',
          value: AlarisSmsStatus.VND_CHN_NOT_BND
        },{
          label: 'ci.common.alaris.buffered_vlb',
          value: AlarisSmsStatus.BUFFERED_VLB
        },{
          label: 'ci.common.alaris.same_chn_skipped_on_reroute',
          value: AlarisSmsStatus.SAME_CHN_SKIPPED_ON_REROUTE
        },{
          label: 'ci.common.alaris.vnd_chnl_sbm_queue_ovl',
          value: AlarisSmsStatus.VND_CHNL_SBM_QUEUE_OVL
        },{
          label: 'ci.common.alaris.sent',
          value: AlarisSmsStatus.SENT
        },{
          label: 'ci.common.alaris.no_routes',
          value: AlarisSmsStatus.NO_ROUTES
        },{
          label: 'ci.common.alaris.buffered_by_rule',
          value: AlarisSmsStatus.BUFFERED_BY_RULE
        },{
          label: 'ci.common.alaris.submit_resp_timeout',
          value: AlarisSmsStatus.SUBMIT_RESP_TIMEOUT
        }

      ],
}


@Injectable()
export class StatusAlarisMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => entities.map(e => {
        return {
          ...e,
          label: this.translateService.instant(e.label)
        }
      })
    )

}
